.header__mobile_btn {
  display: none;
  margin-left: 20px;
  padding: 5px;
  cursor: pointer;
  position: relative;
  z-index: 10;

  @include respond-to(menu) {
    display: block;
  }

  span {
    position: relative;
    display: block;
    width: 20px;
    height: 2px;
    background: $white;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 0 0;
    transition: transform 0.55s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.55s cubic-bezier(0.77, 0.2, 0.05, 1),
      opacity 0.55s ease;

    .solutions_header_black & {
      background: #2a333d;
    }

    .fixed &,
    .privacy_policy & {
      background: $dark;
    }

    &:not(:last-child) {
      margin-bottom: 4px;
    }

    &:last-child {
      transform-origin: 0 100%;
    }
  }

  .open & {
    span {
      opacity: 1;
      transform: rotate(45deg) translate(2px, -3px);
      background: $dark;
    }

    span:nth-child(2) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }

    span:last-child {
      transform: rotate(-45deg) translate(2px, 3px);
    }
  }
}

.header__mobile {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  padding: 70px 24px 56px;

  &::before {
    content: '';
    top: 0;
    right: 0;
    background-color: #f3f5f9;
    position: absolute;
    z-index: 1;
    width: 20px;
    height: 20px;
    transition: all 0.55s ease;
    opacity: 0;
    border-radius: 12px;
  }

  @include respond-to(menu) {
    display: block;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s opacity linear, 0.3s visibility linear;
  }

  .open & {
    @include respond-to(menu) {
      opacity: 1;
      visibility: visible;
      transition: 0.25s opacity linear, 0.25s visibility linear;

      &::before {
        top: 50%;
        right: 50%;
        width: 300vh;
        height: 300vh;
        margin: -150vh -150vh 0 0;
        opacity: 1;
        border-radius: 150vh;
      }
    }
  }

  &__inner {
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__menu {
      margin-top: 24px;
      position: relative;
      z-index: 1;
      width: 100%;

      &__list {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        &__item {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;

          &:not(:last-child) {
            margin-bottom: 36px;
            transition: margin-bottom 0.55s ease-in;
          }

          &_nest:not(:last-child) {
            margin-bottom: 26px;
            transition: margin-bottom 0.55s ease-out;
          }

          & > a {
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: $black-100;
            margin-bottom: 0;
            transition: opacity 0.55s linear, color 0.55s linear, margin-bottom 0.55s ease-in;

            svg {
              position: relative;
              top: 2px;
              margin-left: 6px;
              transition: transform 0.55s ease-out, color 0.55s ease-out, opacity 0.55s ease-out;
            }

            &.active {
              color: $purple;
              margin-bottom: 26px;
              transition: color 0.55s linear, margin-bottom 0.55s ease-out;

              a {
                margin-bottom: 26px;
              }

              svg {
                transform: rotate(180deg);
              }
            }
          }

          &__sub {
            &_active {
              max-height: 1000px;
              transition: max-height 0.55s ease-in;
            }
          }
        }
      }
    }
  }
}
